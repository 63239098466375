@use 'src/styles/triangle.scss' as *;
$removeButtonDefaultRadius: 20px;

// NOTE: if arrow is down or up, width and height are applied accordingly
// if arrow is left or right - width is height and height is width
$arrowWidth: 15px;
$arrowHeight: 10px;

:local {
  .wrapper {
    display: inline-flex;
    padding: 5px;
    position: relative;
    flex-shrink: 1;
    flex-grow: 0;
    max-width: 100%;
  }

  .inner-wrapper {
    width: 100%;
  }

  .type {
    font-size: 8px;
    margin-right: 5px;
    color: #c62f2f;
    min-width: 17px;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;

    &--neg {
      color: #2f9dc6;
    }
  }

  .diagnoses {
    color: #2f9dc6;
  }

  .remove-button {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    border: 1px solid black;
    width: $removeButtonDefaultRadius * 2;
    height: $removeButtonDefaultRadius * 2;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-transition: border-color 0.2s;
            transition: border-color 0.2s;
    background: white;
    padding: 0;

    &:focus,
    &:active {
      outline: none;
    }

    &:before,
    &:after {
      content: '';
      background: black;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 60%;
      height: 1px;
      -webkit-transition: background 0.2s;
              transition: background 0.2s;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .element {
    padding: 8px 10px;
    border: 1px solid #dddddd;
    background: #e8f0f3;
    text-align: center;
    cursor: pointer;
    // text-transform: uppercase;
    position: relative;
    width: 100%;


    &:focus,
    &:active {
      outline: none;
    }
  }

  .element-name {
    text-align: left;

    // ie10 overflow fix
    width: 100%;
    max-width: 100%;
    display: block;
  }

  .element-flex-container {
    display: flex;
    align-items: center;
  }

  .popup-wrapper {
    padding-right: $arrowHeight;
  }

  .popup {
    position: relative;
    white-space: nowrap;
    padding: 10px;
    border-radius: 40px;
    border: 1px solid #b3b3b3;
    background: #ffffff;
    text-align: center;
    visibility: visible;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;


    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 100%;
      border-bottom: none;
      border-left: calc($arrowWidth / 2) solid transparent;
      border-right: calc($arrowWidth / 2) solid transparent;
    }

    &:before {
      margin-top: 0;
      border-top: $arrowHeight solid #b3b3b3;
    }

    &:after {
      margin-top: -1px;
      border-top: $arrowHeight solid #ffffff;
    }
  }

  .diagnoses-list-wrapper {
    padding-top: 10px;
    max-width: 300px;
  }

  .diagnoses-list-inner-wrapper {
    background: #ffffff;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -10px;
    }

    &:before {
      @include triangle(top, 10px, 10px, #b3b3b3);
    }

    &:after {
      @include triangle(top, 10px, 10px, white);
      margin-bottom: -1px;
    }
  }
}

:global {
  .enclosedElementDiagnoses-element.enclosedElementDiagnoses-out-of-bounds-top,
  .enclosedElementDiagnoses-element.enclosedElementDiagnoses-out-of-bounds-bottom {
    // display: none doesn't work!!!
    position: fixed !important;
    top: -9000px !important;
    left: -9000px !important;
  }

  .enclosedElementDiagnoses {
    &-element {
      z-index: 6;

      &-attached-bottom {
        :local {
          .diagnoses-list-wrapper {
            padding-top: 0;
            padding-bottom: 10px;
          }

          .diagnoses-list-inner-wrapper {
            &:before,
            &:after {
              bottom: initial;
              top: 100%;
            }

            &:before {
              @include triangle(bottom, 10px, 10px, #b3b3b3);
            }

            &:after {
              @include triangle(bottom, 10px, 10px, white);
              margin-bottom: 0;
              margin-top: -1px;
            }
          }
        }
      }
    }
  }

  .subElementInput-element.subElementInput-out-of-bounds-top,
  .subElementInput-element.subElementInput-out-of-bounds-bottom {
    // display: none doesn't work!!!
    position: fixed !important;
    top: -9000px !important;
    left: -9000px !important;
  }

  .subElementInput {
    &-element {
      z-index: 1;
    }
  }

  .subElements-element.subElements-out-of-bounds-top,
  .subElements-element.subElements-out-of-bounds-bottom {
    // display: none doesn't work!!!
    position: fixed !important;
    top: -9000px !important;
    left: -9000px !important;
  }

  .subElements {
    &-element {
      z-index: 1;
    }
  }

  .enclosedElement-element {
    z-index: 10 !important;
  }

  .enclosedElement-element.enclosedElement-out-of-bounds-top,
  .enclosedElement-element.enclosedElement-out-of-bounds-bottom {
    // display: none doesn't work!!!
    position: fixed !important;
    top: -9000px !important;
    left: -9000px !important;
  }

  .enclosedElement {
    &-element {
      z-index: 1;

      &-attached-left {
        :local {
          .popup-wrapper {
            padding-right: 0;
            padding-left: $arrowHeight;
          }

          .popup {
            &:after,
            &:before {
              left: auto;
              right: 100%;
              top: 50%;
              border-left: none;
            }

            &:before {
              margin-left: 0;
              margin-right: 0;
              border-right: $arrowHeight solid #b3b3b3;
            }

            &:after {
              margin-left: 0;
              margin-right: -1px;
              border-right: $arrowHeight solid #ffffff;
            }
          }
        }
      }
    }
  }
}
